import {Logger, Transport, LogLevel, LogMessage, InternalLogMessage} from '@/logger/types';
import {prefab} from '@prefab-cloud/prefab-cloud-js';
import {Prefab as PrefabNode} from '@prefab-cloud/prefab-cloud-node';
import {getLogLevelForEnvironment} from '@/config';

const DEFAULT_LOG_LEVEL = getLogLevelForEnvironment();

// Message format maps should take all arguments except
// itself push the actual format message value
type MessageFormatMapping = Record<
  string,
  keyof Omit<InternalLogMessage, 'formattedMessage' | 'messageFormatMapping'>
>;

// the message format function should take all arguments except for itself
interface MessageFormatArgs extends Omit<InternalLogMessage, 'formattedMessage'> {
  messageFormatMapping: MessageFormatMapping;
}

type Prefab = {
  shouldLog: typeof prefab.shouldLog | typeof PrefabNode.prototype.shouldLog;
};

export interface LoggerArgs {
  prefab: Prefab;
  name: string;
  transports: Transport[];
  messageFormat?: string | ((args: MessageFormatArgs) => string);
  messageFormatMapping?: MessageFormatMapping;
  minLogLevel?: LogLevel;
}

export function createLogger({
  prefab,
  name,
  transports,
  messageFormat = '%t %n-%l %m',
  messageFormatMapping = {
    t: 'timestamp',
    n: 'loggerName',
    l: 'logLevel',
    m: 'message',
  },
  minLogLevel = DEFAULT_LOG_LEVEL,
}: LoggerArgs): Logger {
  function sendLog(logLevel: LogLevel, args: LogMessage, error?: Error): void {
    const shouldLog = prefab.shouldLog({
      loggerName: name,
      desiredLevel: logLevel,
      defaultLevel: minLogLevel,
    });

    if (!shouldLog) {
      return;
    }

    const timestamp = new Date().toISOString();
    const messageFormatArgs = {
      ...args,
      messageFormatMapping,
      logLevel,
      loggerName: name,
      timestamp,
    };

    const internalArgs = {
      ...args,
      timestamp,
      logLevel,
      loggerName: name,
      formattedMessage:
        typeof messageFormat === 'function'
          ? messageFormat(messageFormatArgs)
          : formatMessage(messageFormat, messageFormatArgs),
    };

    if (error) {
      transports.forEach((transport) => transport[logLevel](internalArgs, error));
    } else {
      transports.forEach((transport) => transport[logLevel](internalArgs));
    }
  }

  return {
    fatal(args: LogMessage, error: Error): void {
      sendLog(LogLevel.Fatal, args, error);
    },
    error(args: LogMessage, error: Error): void {
      sendLog(LogLevel.Error, args, error);
    },
    warn(args: LogMessage): void {
      sendLog(LogLevel.Warn, args);
    },
    info(args: LogMessage): void {
      sendLog(LogLevel.Info, args);
    },
    debug(args: LogMessage): void {
      sendLog(LogLevel.Debug, args);
    },
    trace(args: LogMessage): void {
      sendLog(LogLevel.Trace, args);
    },
  };
}

function formatMessage(format: string, args: MessageFormatArgs): string {
  const messageFormatMapping = args.messageFormatMapping;

  return format.replace(/(%[a-zA-z]+)/g, (_, value) => {
    const field = messageFormatMapping[value.substr(1)];

    if (typeof field === 'undefined') {
      return '';
    }

    const lookupValue = args[field];

    if (typeof lookupValue === 'undefined') {
      return '';
    } else if (typeof lookupValue === 'object') {
      // TODO: Support digging into `data` object
      return JSON.stringify(lookupValue);
    } else if (field === 'logLevel') {
      return lookupValue.toUpperCase();
    }

    return lookupValue;
  });
}
