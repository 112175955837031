'use client';

import {ReactNode, ReactElement, createContext, useContext} from 'react';
import {datadogRum, RumInitConfiguration} from '@datadog/browser-rum';
import {
  BE_BASE_ENDPOINT,
  CLIENT_NAME,
  CLIENT_VERSION,
  DATADOG_RUM_APPLICATION_ID,
  DATADOG_RUM_CLIENT_TOKEN,
  DD_RUM_CX_APPLICATION_ID,
  DD_RUM_CX_CLIENT_TOKEN,
  DATADOG_SITE,
  getCurrentEnvironment,
  FE_DD_PROXY_ENDPOINT,
} from '@/config';

const DatadogRUMContext = createContext(datadogRum);

type DatadogProviderProps = {children: ReactNode};

export function DatadogRumProvider({children}: DatadogProviderProps): ReactElement {
  return <DatadogRUMContext.Provider value={datadogRum}>{children}</DatadogRUMContext.Provider>;
}

export function useRumClient() {
  const context = useContext(DatadogRUMContext);
  if (context === undefined) {
    throw new Error(`${useRumClient.name} must be used within a ${DatadogRumProvider.name}`);
  }
  return context;
}

export function initDatadogRum() {
  const config: RumInitConfiguration = {
    applicationId: DATADOG_RUM_APPLICATION_ID,
    clientToken: DATADOG_RUM_CLIENT_TOKEN,
    site: DATADOG_SITE,
    service: CLIENT_NAME,
    env: getCurrentEnvironment(),
    version: CLIENT_VERSION,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingUrls: [BE_BASE_ENDPOINT],
    proxy: (options) => {
      // set up a proxy path that does not include rum?dd... because that is what most blockers are looking for
      return `${FE_DD_PROXY_ENDPOINT}?path=${options.path}&${options.parameters}`;
    },
  };
  datadogRum.init(config);
}

export function initAtlasCxDatadogRum() {
  const config: RumInitConfiguration = {
    applicationId: DD_RUM_CX_APPLICATION_ID,
    clientToken: DD_RUM_CX_CLIENT_TOKEN,
    site: DATADOG_SITE,
    service: CLIENT_NAME,
    env: getCurrentEnvironment(),
    version: CLIENT_VERSION,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingUrls: [BE_BASE_ENDPOINT],
    // iFrame specific DD options
    useSecureSessionCookie: true,
    usePartitionedCrossSiteSessionCookie: true,
    proxy: (options) => {
      // set up a proxy path that does not include rum?dd... because that is what most blockers are looking for
      return `${FE_DD_PROXY_ENDPOINT}?path=${options.path}&${options.parameters}`;
    },
  };
  datadogRum.init(config);
}
