import {prefab} from '@prefab-cloud/prefab-cloud-js';
import {ApplicationConfiguration} from '@/config';
import {createLogger} from '@/logger/logger';
import {consoleTransport} from '@/logger/transports/console';
import {datadogTransport} from '@/logger/transports/datadog';
import {LogLevel, LogMessage} from '@/logger/types';

const LOGGER_NAME = 'App';

const transports = [];
transports.push(consoleTransport());

if (ApplicationConfiguration.configureDataDogTransport) {
  transports.push(datadogTransport());
}

const logger = createLogger({prefab, name: LOGGER_NAME, transports});

type Message = LogMessage['message'];
type Data = LogMessage['data'];

export function trace(message: Message, data?: Data) {
  log(LogLevel.Trace, message, data);
}

export function info(message: Message, data?: Data) {
  log(LogLevel.Info, message, data);
}

export function debug(message: Message, data?: Data) {
  log(LogLevel.Debug, message, data);
}

export function warn(message: Message, data?: Data) {
  log(LogLevel.Warn, message, data);
}

export function error(error: Error, message?: Message, data?: Data) {
  const msg = message ?? error.message;
  logError(LogLevel.Error, msg, data, error);
}

export function fatal(error: Error, message?: Message, data?: Data) {
  const msg = message ?? error.message;
  logError(LogLevel.Fatal, msg, data, error);
}

function log(
  level: LogLevel.Trace | LogLevel.Info | LogLevel.Debug | LogLevel.Warn,
  message: Message,
  data: Data,
) {
  logger[level]({message, data});
}

function logError(
  level: LogLevel.Error | LogLevel.Fatal,
  message: Message,
  data: Data,
  error: Error,
) {
  logger[level]({message, data}, error);
}
