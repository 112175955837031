import {Transport, InternalLogMessage} from '@/logger/types';

function consoleTransport(): Transport {
  return {
    fatal(args: InternalLogMessage, error?: Error): void {
      const {formattedMessage, data} = args;
      console.error(formattedMessage, error, data);
    },
    error(args: InternalLogMessage, error?: Error): void {
      const {formattedMessage, data} = args;
      console.error(formattedMessage, error, data);
    },
    warn(args: InternalLogMessage): void {
      const {formattedMessage, data} = args;
      console.warn(formattedMessage, data);
    },
    info(args: InternalLogMessage): void {
      const {formattedMessage, data} = args;
      console.info(formattedMessage, data);
    },
    debug(args: InternalLogMessage): void {
      const {formattedMessage, data} = args;
      console.debug(formattedMessage, data);
    },
    trace(args: InternalLogMessage): void {
      const {formattedMessage, data} = args;
      console.debug(formattedMessage, data);
    },
  };
}

export {consoleTransport};
